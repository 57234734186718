import styles from './Input.module.scss';

import Eye from "../../components/icons/eye.svg";
import EyeSlash from "../../components/icons/eye-slash.svg";
import { useState } from 'react';

const InputField = ({type, name, value, onChange, placeholderText, required, autoComplete}) => {
  const [showPassword, setShowPassword] = useState(false);

  const getInputType = () => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password';
    }
    return type;
  };

  return (
    <div className={styles.inputContainer}>
      <input
        type={getInputType()}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        aria-labelledby={name}
        required={required}
        autoComplete={autoComplete}
      />
      <label className={styles.placeholderText} htmlFor={name} id={name}>
        <div className={styles.text}>{placeholderText}</div>
      </label>
      {type === 'password' && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className={styles.togglePasswordButton}
          aria-label="Toggle password visibility"
        >
          {showPassword ? <EyeSlash /> : <Eye /> }
        </button>
      )}
    </div>
  );
};

export default InputField;
