import { useState } from "react";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import styles from "./styles.module.scss";
import Button from "../Button";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import InputField from "../input/Input";

export default function ResetPasswordForm({ switchForm, formState, email, setEmail }) {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formState === "forgotPassword") {
        const output = await resetPassword({ username: email });
        handleResetPasswordNextSteps(output);
      } else if (formState === "confirmReset") {
        if (!code || !password || password !== confirmPassword) {
          setError("Please provide valid input and ensure passwords match.");
          return;
        }

        await confirmResetPassword({ username: email, confirmationCode: code, newPassword: password });
        setMessage("Password reset successfully! You can now log in.");
        switchForm("signIn");
      }
    } catch (error) {
      setError(error.message || "An error occurred.");
    }
  };

  const handleResetPasswordNextSteps = (output) => {
    const { nextStep } = output;

    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        setMessage("Confirmation code sent to your email.");
        switchForm("confirmReset");
        break;

      case "DONE":
        setMessage("Password reset request completed.");
        switchForm("signIn");
        break;

      default:
        setError("Unexpected error in the reset password process.");
        break;
    }
  };

  return (
    <div className={styles.login}>
      <h2>{formState === "confirmReset" ? "Reset Password" : "Forgot Password"}</h2>
      {message && <span className={styles.successMessage}>{message}</span>}
      <form onSubmit={handleSubmit}>
        {formState !== "confirmReset" && (
          <InputField
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholderText="Email"
            required
          />
        )}

        {formState === "confirmReset" && (
          <>
            <InputField
              type="text"
              name="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholderText="Confirmation Code"
              required
            />
            <div>
              <InputField
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholderText="New Password"
                required
                autoComplete="new-password"
              />
              <PasswordStrengthIndicator password={password} />
            </div>

            <InputField
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholderText="Confirm Password"
              required
              autoComplete="new-password"
            />
          </>
        )}

        {error && <span className={styles.errorMessage}>{error}</span>}

        <Button type="submit" className="primary w-100">
          {formState === "confirmReset" ? "Reset Password" : "Submit Request"}
        </Button>
      </form>

      <p>
        Remembered your password?{" "}
        <span className="link" onClick={() => switchForm("signIn")}>
          Login
        </span>
      </p>
    </div>
  );
}
