import { useState } from "react";
import Button from "../Button";
import { signUp, confirmSignUp } from "aws-amplify/auth";
import { useUser } from "../../context/user";
import styles from "./styles.module.scss";
import GoogleIcon from "../../components/icons/social/google.svg";
import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import InputField from "../input/Input";

export default function SignUpForm({ switchForm, formState, onSignInSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const { signIn } = useUser();

  const isValidBirthdate = () => {
    if (!birthdate) return false;

    const inputDate = new Date(birthdate);
    const today = new Date();
    const age = today.getFullYear() - inputDate.getFullYear();

    // Check if the user is 21 years or older
    if (age > 21 || (age === 21 && today >= new Date(inputDate.setFullYear(today.getFullYear() - 21)))) {
      return true;
    }
    return false;
  };

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!isValidBirthdate()) {
      setError("You must be 21 years or older to sign up.");
      return;
    }

    try {
      const formattedPhone = phone.startsWith("+") ? phone : `+1${phone}`;

      const { isSignUpComplete, nextStep } = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            phone_number: formattedPhone,
            birthdate,
          },
          autoSignIn: true,
        },
      });

      if (isSignUpComplete) {
        setMessage("Sign-up successful! You are now signed in.");
        onSignInSuccess();  // Redirect after auto sign-in
      } else if (nextStep?.signUpStep === "CONFIRM_SIGN_UP") {
        setMessage("Please enter the confirmation code sent to your email.");
        switchForm("confirmSignUp");
      } else {
        setError("Unexpected sign-up response.");
      }
    } catch (error) {
      setError(error.message || "An error occurred during sign-up.");
    }
  };

  const handleConfirmSignUp = async () => {
    try {
      await confirmSignUp({ username: email, confirmationCode });

      setMessage("Account confirmed! Attempting to sign in...");

      const result = await signIn(email, password);
      if (result.success) {
        setMessage("Sign-in successful.");
        onSignInSuccess();  // Redirect after successful sign-in
      } else {
        setError("Sign-in failed. Please try logging in manually.");
        switchForm("signIn");
      }
    } catch (error) {
      setError(error.message || "An error occurred during confirmation.");
    }
  };

  const handleBirthdateChange = (e) => {
    const input = e.target.value;

    // Allow only valid date strings (e.g., "YYYY-MM-DD")
    if (/^\d{4}-\d{0,2}-\d{0,2}$/.test(input)) {
      setBirthdate(input);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState === "signUp") {
      handleSignUp();
    } else if (formState === "confirmSignUp") {
      handleConfirmSignUp();
    }
  };

  return (
    <div className={styles.login}>
      <h2>{formState === "signUp" ? "Sign Up" : "Confirm Your Email"}</h2>

      {formState === "signUp" && (
        <div className={styles.social}>
          <Button type="button" className="google">
            <GoogleIcon />
            Sign Up with Google
          </Button>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {formState === "signUp" ? (
          <>
            <InputField
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholderText="Email"
              required
            />
            <div>
              <InputField
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholderText="Create a password"
                required
                autoComplete="new-password"
              />

              <PasswordStrengthIndicator password={password} />
            </div>

            <InputField
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholderText="Confirm password"
              required
            />

            <InputField
              type="tel"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholderText="Phone Number"
            />
            
            <InputField
              type="date"
              name="birthdate"
              value={birthdate}
              onChange={handleBirthdateChange}
              placeholderText="Birthdate"
              required
            />
          </>
        ) : (
          <InputField
            type="text"
            name="confirmationCode"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            placeholderText="Confirmation Code"
            required
          />
        )}

        {error && <span className={styles.errorMessage}>{error}</span>}
        {message && <span className={styles.successMessage}>{message}</span>}

        <Button type="submit" className="primary w-100">
          {formState === "signUp" ? "Sign Up" : "Confirm"}
        </Button>

        {formState === "signUp" && (
          <p>
            Already have an account?{" "}
            <span className="link" onClick={() => switchForm("signIn")}>
              Login
            </span>
          </p>
        )}
      </form>
    </div>
  );
}
