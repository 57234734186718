import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useUser } from "../../context/user";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import ResetPasswordForm from "./ResetPasswordForm";

export default function AuthForm({ view }) {
  const [formState, setFormState] = useState(view); // 'signIn', 'signUp', 'confirmSignUp', 'forgotPassword', 'confirmReset'
  const router = useRouter();
  const { loadingUser, isAuthenticated } = useUser();
  const [email, setEmail] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [formState]);

  useEffect(() => {
    if (!loadingUser && isAuthenticated) {
      router.replace("/profile/account");
    }
  }, [isAuthenticated, loadingUser, router]);

  if (loadingUser || isAuthenticated) {
    return null; // TODO should be loading spinner
  }

  const handleSignInSuccess = () => {
    router.replace("/profile/account");
  };

  return (
    <>
      {formState === "signIn" && (
        <LoginForm switchForm={setFormState} email={email} setEmail={setEmail} />
      )}
      {(formState === "signUp" || formState === "confirmSignUp") && (
        <SignUpForm switchForm={setFormState} formState={formState} onSignInSuccess={handleSignInSuccess} />
      )}
      {(formState === "forgotPassword" || formState === "confirmReset") && (
        <ResetPasswordForm switchForm={setFormState} formState={formState} email={email} setEmail={setEmail} />
      )}
    </>
  );
}
