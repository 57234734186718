import styles from "./styles.module.scss";
import Check from "../../components/icons/check.svg";
import Xmark from "../../components/icons/xmark.svg";

export default function PasswordStrengthIndicator({ password }) {

  function checkPasswordStrength(password) {
    return {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      symbol: /[^a-zA-Z0-9]/.test(password),
    };
  }
  const strength = checkPasswordStrength(password);

  return (
    <div className={styles.passwordStrength}>
    Password must include:
    <ul>
      <li>
        <icon className={strength.length ? styles.valid : styles.invalid}>
        {strength.length ? <Check /> : <Xmark />}
        </icon>
        8+ characters
      </li>
      <li>
         <icon className={strength.lowercase ? styles.valid : styles.invalid}>
        {strength.lowercase ? <Check /> : <Xmark />}
        </icon>
        1 lowercase letter
      </li>
      <li>
      <icon className={strength.uppercase ? styles.valid : styles.invalid}>
        {strength.uppercase ? <Check /> : <Xmark />}
        </icon>
        1 uppercase letter
      </li>
      <li>
      <icon className={strength.number ? styles.valid : styles.invalid}>
        {strength.number ? <Check /> : <Xmark />}
        </icon>
        1 number
      </li>
      <li>
      <icon className={strength.symbol ? styles.valid : styles.invalid}>
        {strength.symbol ? <Check /> : <Xmark />}
        </icon>
        1 special character (@, #, $, etc.)
      </li>
    </ul>
    </div>
  );
}
