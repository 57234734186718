import { useState } from "react";
import { useRouter } from "next/router";
import { useUser } from "../../context/user";
import Button from "../Button";
import { signInWithRedirect, getCurrentUser } from "aws-amplify/auth";
import styles from "./styles.module.scss";
import GoogleIcon from "../icons/social/google.svg";
import InputField from "../input/Input";

export default function LoginForm({ switchForm, email, setEmail }) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const router = useRouter();
  const { signIn, loadingUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await signIn(email, password);
      if (!result.success) {
        setError(result.message);
      } else {
        setMessage("Login successful!");
        router.replace("/profile/account");
      }
    } catch (error) {
      setError(error.message || "An error occurred.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithRedirect({ provider: "Google" });
    } catch (error) {
      setError("Failed to initiate Google sign-in.");
    }
  };

  return (
    <div className={styles.login}>
      <h2>Login</h2>

      <div className={styles.social}>
        <Button type="button" className="google" onClick={handleGoogleSignIn}>
          <GoogleIcon />
          Login with Google
        </Button>
      </div>

      <div className={styles.orDivider}>or</div>

      <form onSubmit={handleSubmit}>
        <div>
          <InputField
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholderText="Email"
            required
          />
        </div>
        <div>
        <InputField
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholderText="Password"
          required
        />
        <span className={styles.forgotPassword} onClick={() => switchForm("forgotPassword")}>
          Forgot Password?
        </span>
        </div>

        {error && <span className={styles.errorMessage}>{error}</span>}
        {message && <span className={styles.successMessage}>{message}</span>}

        <Button type="submit" className="primary w-100" disabled={loadingUser}>
          {loadingUser ? "Loading..." : "Login"}
        </Button>
      </form>

      <div className="fs-300">
        Don&apos;t have an account? <span className="link" onClick={() => switchForm("signUp")}>Sign Up</span>
      </div>
    </div>
  );
}
